/* .navbar {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
}

.navbar.hidden {
  transform: translateY(-100%);
}

.logo {
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-list {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 1rem;
}

.nav-link {
  text-decoration: none;
  color: #808080;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #404040;
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.5rem;
  }

  .logo {
    margin-right: 1rem;
  }

  .nav-list {
    flex-wrap: wrap;
    justify-content: flex-end;
    flex: 1;
  }

  .nav-item {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }
} */


.navbar {
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem; /* Increased padding */
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  height: 70px; /* Increased height */
}

.navbar.hidden {
  transform: translateY(-100%);
}

.logo {
  width: 130px; /* Increased size */
  height: 130px; /* Increased size */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  left: 0;
  transform: none;
}

.menu-icon {
  display: none;
  cursor: pointer;
  z-index: 1001;
}

.hamburger {
  width: 35px; /* Slightly larger hamburger icon */
  height: 4px; /* Slightly thicker lines */
  background-color: #808080;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 35px; /* Slightly larger hamburger icon */
  height: 4px; /* Slightly thicker lines */
  background-color: #808080;
  transition: all 0.3s ease-in-out;
}

.hamburger::before {
  transform: translateY(-12px); /* Adjusted for larger icon */
}

.hamburger::after {
  transform: translateY(12px); /* Adjusted for larger icon */
}

.hamburger.open {
  background-color: transparent;
}

.hamburger.open::before {
  transform: rotate(45deg);
}

.hamburger.open::after {
  transform: rotate(-45deg);
}

.nav-list {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 1.5rem; /* Increased spacing */
}

.nav-link {
  text-decoration: none;
  color: #808080;
  font-weight: 500;
  transition: color 0.3s ease;
  font-size: 1.1rem; /* Slightly larger font */
}

.nav-link:hover {
  color: #404040;
}

@media screen and (max-width: 768px) {
  .navbar {
    justify-content: space-between;
    padding: 1.2rem; /* Adjusted padding for mobile */
  }
  
  .logo {
    width: 95px; /* Adjusted for mobile */
    height: 95px; /* Adjusted for mobile */
  }

  .menu-icon {
    display: block;
  }

  .nav-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    padding: 1.2rem; /* Increased padding */
  }

  .nav-list.active {
    display: flex;
  }

  .nav-item {
    margin: 0.7rem 0; /* Increased spacing */
  }
}