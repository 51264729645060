.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25D366;
    border-radius: 50%;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    padding: 10px;
  }
  
  .whatsapp-icon {
    width: 50px;
    height: 50px;
  }